<template>
  <div>
    <h3 class="headline--lg text-bold">Setting</h3>

    <general-setting />
    <referral-setting />
    <staking-setting />
  </div>
</template>

<script>
import GeneralSetting from './General'
import ReferralSetting from './Referral'
import StakingSetting from './Staking'

export default {
  components: {
    GeneralSetting,
    ReferralSetting,
    StakingSetting
  }
}
</script>
